
import './App.css';
import Page from './page'
function App() {
  return (
   <div>
     <Page/>
   </div>
  );
}

export default App;
