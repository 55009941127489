import React from 'react'
import './about.css'
import gopi from '../assets/images/GopiD310B45Ca11862F0E4A014.png'
import kalanithi from '../assets/images/Kalanithi.jpeg'
import gowtham from '../assets/images/Gowtham.png'
import krishna from '../assets/images/Krishna.jpeg'
import jhon from '../assets/images/Jhon.png'
import mukesh from '../assets/images/mukesh.jpeg'
import mukunth from '../assets/images/Mukunth.png'
import karthikeyan from '../assets/images/karthikeyan.jpg'
import { motion } from 'framer-motion';

const fadeUpAnimation = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

function about() {
  return (
    <div className='main-about'>
  <section>
            <div className="about-1">
            </div>
        </section>
        <section>
      <div className="about-2">
        <div className="container">
          <div className="row">
            {[ 
              { name: 'KARTHIKEYAN', role: 'FOUNDER', description: 'AI/ML and software developer, delivering innovative, tech-driven solutions to transform businesses and enhance efficiency.', img: karthikeyan },
              { name: 'GOPINATH A', role: 'UI/UX DEVELOPER', description: 'UI/UX developer blending technical skills with design expertise to deliver innovative solutions and engaging experiences.', img: gopi },
              { name: 'MUKUNTH J', role: 'IOT DEVELOPER', description: 'An IoT developer designs and builds systems that connect and manage devices over the internet, enabling data exchange and automation.', img: mukunth },
              { name: 'MUKESH K', role: 'IOT DEVELOPER', description: 'An IoT developer designs and builds systems that connect and manage devices over the internet, enabling data exchange and automation.', img: mukesh },
              { name: 'JOHN J', role: 'AI DEVELOPER', description: 'An AI developer creates algorithms and models to enable machines to learn and perform tasks requiring human intelligence.', img: jhon },
              { name: 'GOWTHAM S', role: 'BACKEND DEVELOPER', description: 'A backend developer manages server-side components, focusing on database, server logic, and performance to ensure smooth application functionality.', img: gowtham },
              { name: 'KALANITHISELVAN G', role: 'FRONTEND DEVELOPER', description: 'A frontend developer builds the visual and interactive elements of websites and applications, ensuring an engaging user experience.', img: kalanithi },
              { name: 'KRISHNA', role: 'APP DEVELOPER', description: 'An app developer designs and builds software applications for mobile or desktop platforms, focusing on functionality and user experience.', img: krishna },
            ].map((member, index) => (
              <motion.div
                className="col-lg-3 col-12 col-sm-11 col-md-4 body"
                variants={fadeUpAnimation}
                initial="hidden"
                whileInView="visible"
                transition={{ duration: 2 }}
                viewport={{ once: false }} 
                key={index}
              >
                <div className="image-div">
                  <center><img src={member.img} alt={member.name} /></center>
                </div>
                <h1>{member.name}</h1>
                <strong><center>{member.role}</center></strong>
                <p>{member.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
    </div>
  )
}

export default about