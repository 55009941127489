import afot from '../assets/images/AFOT.png'
import { Link } from 'react-router-dom';
import './navbar.css'
import logo from '../assets/images/AfotLogo20F8259Dc801E95Ae3E7311.png'
import { useState } from 'react';
import menu from '../New folder/menu.svg'
import xmenu from '../New folder/x-menu.svg'

function BasicExample() {

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(prevState => !prevState);
  
  };

  const closeNav = () => {
    setIsOpen(false);
  };
  return (
    <div className='main-nav'>
          <div >
          <img className='fluid ' src={logo} alt="" />
          </div>
          <div >
          <img className='fluid-2 ' src={afot} alt="" />
          </div>
          <div>
      <div className="button-nav" onClick={toggleOpen}>
      
      <img src={isOpen ? xmenu : menu} alt="Menu toggle icon" />
       
      </div>
      <div className='nav1'>
        <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
        <li><Link className='nav-link' to='/' onClick={closeNav}>Home</Link></li>
            <li><Link className='nav-link' to='/about' onClick={closeNav}>About</Link></li>
            {/* <li><a className='nav-link' href="#services" onClick={closeNav}>Services</a></li> */}
            <li> <Link className='nav-link' to="/#services" onClick={closeNav}>
                Services
              </Link> </li>
            <li><Link className='nav-link' to='/contact' onClick={closeNav}>Contact</Link></li>
        </ul>
      </div>
    </div>
         
 
    
    </div>
  );
}

export default BasicExample;