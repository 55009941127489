import React from 'react'
import './home.css'
import { Link } from 'react-router-dom';
import A1 from '../assets/images/output-onlinegiftools.gif'
// import A2 from '../assets/images/gif2.gif'
import App from '../assets/images/MobileApp1228352713.png'
import iot from '../assets/images/InternetThings173230311.png'
import ai from '../assets/images/Neural28458451.png'
import web from '../assets/images/MobileApp122835271.png'
import cyber from '../assets/images/MobileApp1228352711.png'
import asset from '../assets/images/MobileApp1228352712.png'

import react from '../assets/images/ReactRemovebgPreview5D14F2A5D078088221A31.png'
import Python from '../assets/images/Ellipse3.png'
import css from '../assets/images/Css463E319Bad8E0Edf1Ef71.png'
import php from '../assets/images/PhpRemovebgPreviewF6B34B61Cf9D3F8047292.png'
import node from '../assets/images/NodeRemovebgPreviewC72Be7Faac53510610812.png'
import html from '../assets/images/HtmlRemovebgPreview04977Af14596Deb4E9Cc2.png'
import mysql from '../assets/images/MysqlRemovebgPreviewD94F3B119C5Fe2F69A561.png'
import linux from '../assets/images/LinuxRemovebgPreview94B18C8Eafbc49Acac9B2.png'


import logo from '../assets/images/AfotLogo20F8259Dc801E95Ae3E7311.png'

import  { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Home = () => {
    
    const location = useLocation();

    // Scroll to services section if URL contains #services
    useEffect(() => {
      if (location.hash === '#services') { 
        const servicesSection = document.getElementById('services');
        if (servicesSection) {
          servicesSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
  return (
    <div style={{overflow:'hidden'}}>
      <section className='sec-1'>
    <div className="div-1">
        <div className="row">
            <div 
                className="col-lg-7 col-7 col-sm-6 col-md-6 cont" 
                data-aos="fade-right" 
                data-aos-duration="1000" // Duration in milliseconds
                data-aos-delay="200" // Delay in milliseconds
            >
                <h1>All for one tech</h1>
                <p>
                We provide comprehensive Software and Hardware solutions for your projects, offering freelance support and guidance in project development. Let us be the reliable partner you need to accomplish your objectives.
                </p>
            </div>
            <div 
                className="col-lg-5 col-5 col-sm-6 col-md-6" 
                data-aos="fade-left" 
                data-aos-duration="1000" // Duration in milliseconds
                data-aos-delay="200" // Delay in milliseconds
            >
                <img class="circle arc1" src={A1} alt="A representation of tech services" />
                {/* <img class="circle arc2" src={A2} alt="" /> */}
            </div>
        </div>
    </div>
</section>



        <section id="services">
            <div className="div-2">
                <h1>our services</h1>
                <div className="row card-container ">
                    <div className=" box"z >
                        <img src={App} alt="" /> 
                        <h2>app development</h2>
                        <p>App  development  involves  creating functional,  user-friendly  software for  mobile</p>

                    </div>
                    <div className=" box">
                        <img src={iot} alt="" /> 
                        <h2>IOT Development</h2>
                        <p>IoT development creates devices that communicate over the internet for smart automation.</p>

                    </div>
                    <div className=" box">
                        <img src={ai} alt="" /> 
                        <h2>AI/ML Development</h2>
                        <p>AI/ML development involves creating systems that learn, predict and improve automatically.</p>

                    </div>
                    <div className=" box">
                        <img src={web} alt="" /> 
                        <h2>Web Development</h2>
                        <p>Web development involves creating functional, user-friendly software for Destop</p>

                    </div>
                    <div className=" box">
                        <img src={cyber} alt="" /> 
                        <h2>Cyber Security</h2>
                        <p>Cybersecurity protects digital systems and data from unauthorized access and attacks.</p>

                    </div>
                    <div className=" box">
                        <img src={asset} alt="" /> 
                        <h2>vulnerability assessment</h2>
                        <p>A vulnerability assessment identifies and evaluates security weaknesses in systems and networks.</p>

                    </div>
                    
                    
                </div>
            </div>
        </section>

        <section>
            <div className="div-3">
                <h1>The Technology We Are Currently Utilizing</h1>
                <div class="infinity-animation">
                    <div className="slide">
                    <center> <img src={react} alt="" /></center>
                        <h5>reactjs</h5>
                    </div>
                    <div className="slide">
                    <center> <img src={html} alt="" /> </center>
                        <h5>Html</h5>
                    </div>
                    <div className="slide">
                    <center>   <img src={css} alt="" /> </center>
                        <h5>css</h5>
                    </div>
                    <div className="slide">
                    <center> <img src={node} alt="" /> </center>
                        <h5>node js</h5>
                    </div>
                    <div className="slide">
                    <center> <img src={php} alt="" /> </center>
                        <h5>php</h5>
                    </div>
                    <div className="slide">
                    <center> <img src={Python} alt="" /> </center>
                        <h5>Python</h5>
                    </div>

                    <div className="slide">
                    <center>  <img src={mysql} alt="" /> </center>
                        <h5>mysql</h5>
                    </div>
                    <div className="slide">
                    <center>  <img src={linux} alt="" /> </center>
                        <h5>linux</h5>
                    </div>

                    <div className="slide">
                    <center>  <img src={react} alt="" /> </center>
                        <h5>reactjs</h5>
                    </div>
                    <div className="slide">
                    <center>  <img src={html} alt="" /> </center>
                        <h5>Html</h5>
                    </div>
                    <div className="slide">
                    <center>  <img src={css} alt="" /> </center>
                        <h5>css</h5>
                    </div>
                    <div className="slide">
                    <center>  <img src={node} alt="" /> </center>
                        <h5>node js</h5>
                    </div>
                    <div className="slide">
                    <center>  <img src={php} alt="" /> </center>
                        <h5>php</h5>
                    </div>
                    <div className="slide">
                    <center>  <img src={Python} alt="" /> </center>
                        <h5>Python</h5>
                    </div>

                    <div className="slide">
                    <center>   <img src={mysql} alt="" /> </center>
                        <h5>Mysql</h5>
                    </div>
                    <div className="slide">
                    <center>  <img src={linux} alt="" /> </center>
                        <h5>linux</h5>
                    </div>
                   
                </div>
            </div>
        </section>


         <section style={{overflow:'hidden'}}>
            <div className="div-4">
            <div className="firs row">

<div className=" col-sm-10 col-md-1   img-last "
><div data-aos="fade-right"data-aos-duration="1000"><img src={logo} alt="" /> </div>


</div>

<div className="  col-md-12 col-sm-12 p-last"
div data-aos="fade-up"data-aos-duration="1000">
<p>
your final impact statement goes here. <br />
make it memorable!
</p>
</div>

<div className="last row">


<div className="col-lg-3 col-6 col-sm-3 col-md-3 last-d"div data-aos="fade-right"data-aos-duration="1000">
<h1>pages</h1>
<ul>
<Link className='last-link' to='/'> <li>Home </li></Link>
<Link className='last-link' to='/about'><li>about us</li></Link>
<Link className='last-link' to='/contact'><li>contact us</li></Link>
<Link className='last-link' to='/terms'> <li>Terms & Conditions </li></Link>
</ul>
</div>

<div className="col-lg-4 col-6 col-sm-4 col-md-4  last-d"div data-aos="fade-up"data-aos-duration="1000">
<h1>services</h1>
<p>App Development</p>
<p>Web Development</p>
<p>IOT Development</p>
<p>AI/ML Development</p>

</div>



<div  className="col-lg-4 col-sm-4 col-12 col-md-4  last-d"div data-aos="fade-left"data-aos-duration="1000">
<h1>contact</h1>
<p>allforonetech.in@gmail.com</p>
<p>8925667025</p>
</div>






</div>



</div>
            </div>

        </section> 
      
    </div>
  )
}

export default Home